/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserProfileImage } from './userProfileImage';
import { SubscriptionType } from './subscriptionType';
import { UsersImportDataInner } from './usersImportDataInner';
import { UsersImportStatusEnum } from './usersImportStatusEnum';
import { UsersImportCollumns } from './usersImportCollumns';
import { SupervisorType } from './supervisorType';


/**
 * 
 */
export interface UsersImport { 
    /**
     * 
     */
    id: string;
    /**
     * 
     */
    filename: string;
    /**
     * 
     */
    fileUrl?: string;
    /**
     * 
     */
    type: UsersImport.TypeEnum;
    statusCode: UsersImportStatusEnum;
    /**
     * 
     */
    amount?: number;
    /**
     * 
     */
    reference: string;
    /**
     * 
     */
    columns: Array<string>;
    columnsMapping: UsersImportCollumns;
    /**
     * 
     */
    organisationName?: string;
    /**
     * 
     */
    organisationId?: number;
    /**
     * 
     */
    language: string;
    /**
     * 
     */
    countryCode: string;
    /**
     * 
     */
    trainingIds?: Array<number>;
    /**
     * 
     */
    trainingNames?: Array<string>;
    /**
     * 
     */
    editTS: string;
    /**
     * 
     */
    roles?: Array<SupervisorType>;
    /**
     * 
     */
    editUser: string;
    /**
     * 
     */
    licenseFrom?: string;
    /**
     * 
     */
    licenseTo?: string;
    /**
     * 
     */
    licenseAccountId?: string;
    /**
     * 
     */
    licenseAccountName?: string;
    /**
     * 
     */
    coordinatingSupervisorId?: number;
    /**
     * 
     */
    coordinatingSupervisorName?: string;
    /**
     * 
     */
    data: Array<UsersImportDataInner>;
    editUserRole: SubscriptionType;
    editUserProfileImage?: UserProfileImage;
}
export namespace UsersImport {
    export type TypeEnum = 'assistant' | 'supervisor';
    export const TypeEnum = {
        Assistant: 'assistant' as TypeEnum,
        Supervisor: 'supervisor' as TypeEnum
    };
}


