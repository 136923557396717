/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type UsersImportStatusEnum = 'done' | 'in-progress' | 'inviting' | 'invited' | 'error' | 'map-columns' | 'data-check';

export const UsersImportStatusEnum = {
    Done: 'done' as UsersImportStatusEnum,
    InProgress: 'in-progress' as UsersImportStatusEnum,
    Inviting: 'inviting' as UsersImportStatusEnum,
    Invited: 'invited' as UsersImportStatusEnum,
    Error: 'error' as UsersImportStatusEnum,
    MapColumns: 'map-columns' as UsersImportStatusEnum,
    DataCheck: 'data-check' as UsersImportStatusEnum
};

