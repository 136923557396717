/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type SupervisorType = 'internship-supervisor' | 'local-supervisor' | 'coordinating-supervisor' | 'truncus' | 'lecturer' | 'promotor' | 'facilitator' | 'titular' | 'commission' | 'coach' | 'supervisor-in-training' | 'exception' | 'staff' | 'staff_all';

export const SupervisorType = {
    InternshipSupervisor: 'internship-supervisor' as SupervisorType,
    LocalSupervisor: 'local-supervisor' as SupervisorType,
    CoordinatingSupervisor: 'coordinating-supervisor' as SupervisorType,
    Truncus: 'truncus' as SupervisorType,
    Lecturer: 'lecturer' as SupervisorType,
    Promotor: 'promotor' as SupervisorType,
    Facilitator: 'facilitator' as SupervisorType,
    Titular: 'titular' as SupervisorType,
    Commission: 'commission' as SupervisorType,
    Coach: 'coach' as SupervisorType,
    SupervisorInTraining: 'supervisor-in-training' as SupervisorType,
    Exception: 'exception' as SupervisorType,
    Staff: 'staff' as SupervisorType,
    StaffAll: 'staff_all' as SupervisorType
};

