/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MedbookHistoryChangesInner { 
    /**
     * 
     */
    value?: string;
    /**
     * 
     */
    before?: string;
    /**
     * 
     */
    after?: string;
    /**
     * 
     */
    field: string;
    /**
     * 
     */
    type: MedbookHistoryChangesInner.TypeEnum;
}
export namespace MedbookHistoryChangesInner {
    export type TypeEnum = 'tags' | 'changes' | 'text';
    export const TypeEnum = {
        Tags: 'tags' as TypeEnum,
        Changes: 'changes' as TypeEnum,
        Text: 'text' as TypeEnum
    };
}


