import { Injectable, inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from '../../utils/pipes';
import { SubscriptionType } from '../../utils/api';
import dayjs from 'dayjs';

@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    // injections
    route = inject(ActivatedRoute);
    router = inject(Router);
    toastrService = inject(ToastrService);
    translatePipe = inject(TranslatePipe);

    params: Params;

    constructor() {
        this.route.queryParams.subscribe((next) => {
            this.params = next;
        });
    }

    public getParams() {
        return this.params;
    }

    public getParam(param: string) {
        if (!param || !this.params[param]) {
            return '';
        } else return this.params[param.toString()];
    }

    public addParam(param: string, value: string) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: value
                },
                queryParamsHandling: 'merge'
            });
        }
    }

    public removeParam(param: string, replaceUrl?: boolean) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: null
                },
                queryParamsHandling: 'merge',
                replaceUrl: replaceUrl ?? false
            });
        }
    }

    public downloadBlob(blob: Blob, filename: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;
        a.download = filename;
        a.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        }, 0);
    }

    public downloadLink(blobUrl: string, filename?: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = blobUrl;
        a.download = filename ?? '';
        a.click();
        setTimeout(function () {
            // window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        }, 0);
    }

    public copyToClipboard(val: string, successMessage: string) {
        if (!val) {
            return;
        }
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        if (successMessage) {
            this.toastrService.success(successMessage);
        }
    }

    public toTop(anchor?: string, containerClass?: string) {
        let container: any = window;
        if (containerClass) {
            container = document.getElementsByClassName(containerClass)[0];
        }
        if (!anchor) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            const el = document.getElementById(anchor);
            if (el) {
                const top = el.getBoundingClientRect().top + window.scrollY - 24;
                window.scrollTo({ top: top, behavior: 'smooth' });
            } else {
                window.scrollTo(0, 0);
            }
        }
    }

    public isDefined(item) {
        return item != undefined && item != null;
    }

    public getUUID(parts?: number): string {
        parts = parts || 4;
        const stringArr = [];
        for (let i = 0; i < parts; i++) {
            // tslint:disable-next-line:no-bitwise
            const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            stringArr.push(S4);
        }
        return stringArr.join('-');
    }

    isScrolledIntoView(el) {
        if (!el) return false;
        const rect = el.getBoundingClientRect();
        const elemTop = rect.top;
        const elemBottom = rect.bottom;
        const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
        return isVisible;
    }

    async sha512(string: string): Promise<string> {
        const encoder = new TextEncoder();
        const data = encoder.encode(string);
        const hashBuffer = await crypto.subtle.digest('SHA-512', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
    }

    async sha512Keys(object: any, keys: string[]) {
        const localObject = structuredClone(object);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (localObject[key]) localObject[key] = await this.sha512(localObject[key]);
        }
        return localObject;
    }

    // toasts
    errorToast() {
        this.toastrService.error(this.translatePipe.transform('toast_error'));
    }
    deletedToast() {
        this.toastrService.success(this.translatePipe.transform('toast_deleted'));
    }
    savedToast() {
        this.toastrService.success(this.translatePipe.transform('toast_saved'));
    }

    // search
    memo_getSortingClass = memo((code: string, orderBy: string[]) => {
        if (!code || !orderBy?.length) return '';
        const orderBy_code = orderBy[0].split(' ')[0];
        const orderBy_dir = orderBy[0].split(' ')[1];
        if (orderBy_code === code) {
            return `order-icon--${orderBy_dir}`;
        } else return '';
    });

    getNewSort(code: string, orderBy: string[]) {
        if (!code || !orderBy?.length) return [];
        const orderBy_code = orderBy[0].split(' ')[0];
        const orderBy_dir = orderBy[0].split(' ')[1];
        if (orderBy_code === code) {
            return [`${code} ${orderBy_dir === 'asc' ? 'desc' : 'asc'}`];
        } else {
            return [`${code} asc`];
        }
    }

    // user
    getColorBySubscriptionType(type: SubscriptionType) {
        switch (type) {
            case 'assistant':
                return '#2EA1F8';
            case 'supervisor':
                return '#34C78E';
            case 'staff':
                return '#C52233';
            case 'admin':
                return '#0E79B2';
        }
        return '';
    }

    cleanMatchString(string) {
        return string
            ?.toLowerCase()
            ?.normalize('NFD')
            ?.replace(/\p{Diacritic}/gu, '');
    }
}

// functions
// eslint-disable-next-line @typescript-eslint/ban-types
export function memo<T extends Function>(fnToMemoize: T): T {
    let prevArgs = [{}];
    let result: any;

    return function (...newArgs) {
        if (hasDifferentArgs(prevArgs, newArgs)) {
            result = fnToMemoize(...newArgs);
            prevArgs = newArgs;
        }
        return result;
    } as any;
}

function hasDifferentArgs(prev: unknown[], next: unknown[]) {
    if (prev.length !== next.length) return true;
    for (let i = 0; i < prev.length; i++) {
        if (!Object.is(prev[i], next[i])) return true;
    }
    return false;
}
export function getCookie(cookieName) {
    const cookies = document.cookie;
    const cookieArray = cookies.split('; ');

    for (let i = 0; i < cookieArray.length; i++) {
        const cookie = cookieArray[i];
        const [name, value] = cookie.split('=');

        if (name === cookieName) {
            return decodeURIComponent(value);
        }
    }

    return null;
}

export const mimeToExt = {
    'audio/x-mpeg': 'mpega',
    'application/postscript': 'ps',
    'audio/x-aiff': 'aiff',
    'application/x-aim': 'aim',
    'image/x-jg': 'art',
    'video/x-ms-asf': 'asx',
    'audio/basic': 'ulw',
    'video/x-msvideo': 'avi',
    'video/x-rad-screenplay': 'avx',
    'application/x-bcpio': 'bcpio',
    'application/octet-stream': 'exe',
    'image/bmp': 'dib',
    'text/html': 'html',
    'application/x-cdf': 'cdf',
    'application/pkix-cert': 'cer',
    'application/java': 'class',
    'application/x-cpio': 'cpio',
    'application/x-csh': 'csh',
    'text/css': 'css',
    'application/msword': 'doc',
    'application/xml-dtd': 'dtd',
    'video/x-dv': 'dv',
    'application/x-dvi': 'dvi',
    'application/vnd.ms-fontobject': 'eot',
    'text/x-setext': 'etx',
    'image/gif': 'gif',
    'application/x-gtar': 'gtar',
    'application/x-gzip': 'gz',
    'application/x-hdf': 'hdf',
    'application/mac-binhex40': 'hqx',
    'text/x-component': 'htc',
    'image/ief': 'ief',
    'text/vnd.sun.j2me.app-descriptor': 'jad',
    'application/java-archive': 'jar',
    'text/x-java-source': 'java',
    'application/x-java-jnlp-file': 'jnlp',
    'image/jpeg': 'jpg',
    'application/javascript': 'js',
    'text/plain': 'txt',
    'application/json': 'json',
    'audio/midi': 'midi',
    'application/x-latex': 'latex',
    'audio/x-mpegurl': 'm3u',
    'image/x-macpaint': 'pnt',
    'text/troff': 'tr',
    'application/mathml+xml': 'mathml',
    'application/x-mif': 'mif',
    'video/quicktime': 'qt',
    'video/x-sgi-movie': 'movie',
    'audio/mpeg': 'mpa',
    'video/mp4': 'mp4',
    'video/mpeg': 'mpg',
    'video/mpeg2': 'mpv2',
    'application/x-wais-source': 'src',
    'application/x-netcdf': 'nc',
    'application/oda': 'oda',
    'application/vnd.oasis.opendocument.database': 'odb',
    'application/vnd.oasis.opendocument.chart': 'odc',
    'application/vnd.oasis.opendocument.formula': 'odf',
    'application/vnd.oasis.opendocument.graphics': 'odg',
    'application/vnd.oasis.opendocument.image': 'odi',
    'application/vnd.oasis.opendocument.text-master': 'odm',
    'application/vnd.oasis.opendocument.presentation': 'odp',
    'application/vnd.oasis.opendocument.spreadsheet': 'ods',
    'application/vnd.oasis.opendocument.text': 'odt',
    'application/vnd.oasis.opendocument.graphics-template': 'otg',
    'application/vnd.oasis.opendocument.text-web': 'oth',
    'application/vnd.oasis.opendocument.presentation-template': 'otp',
    'application/vnd.oasis.opendocument.spreadsheet-template': 'ots',
    'application/vnd.oasis.opendocument.text-template': 'ott',
    'application/ogg': 'ogx',
    'video/ogg': 'ogv',
    'audio/ogg': 'spx',
    'application/x-font-opentype': 'otf',
    'audio/flac': 'flac',
    'application/annodex': 'anx',
    'audio/annodex': 'axa',
    'video/annodex': 'axv',
    'application/xspf+xml': 'xspf',
    'image/x-portable-bitmap': 'pbm',
    'image/pict': 'pict',
    'application/pdf': 'pdf',
    'image/x-portable-graymap': 'pgm',
    'audio/x-scpls': 'pls',
    'image/png': 'png',
    'image/x-portable-anymap': 'pnm',
    'image/x-portable-pixmap': 'ppm',
    'application/vnd.ms-powerpoint': 'pps',
    'image/vnd.adobe.photoshop': 'psd',
    'image/x-quicktime': 'qtif',
    'image/x-cmu-raster': 'ras',
    'application/rdf+xml': 'rdf',
    'image/x-rgb': 'rgb',
    'application/vnd.rn-realmedia': 'rm',
    'application/rtf': 'rtf',
    'text/richtext': 'rtx',
    'application/font-sfnt': 'sfnt',
    'application/x-sh': 'sh',
    'application/x-shar': 'shar',
    'application/x-stuffit': 'sit',
    'application/x-sv4cpio': 'sv4cpio',
    'application/x-sv4crc': 'sv4crc',
    'image/svg+xml': 'svgz',
    'application/x-shockwave-flash': 'swf',
    'application/x-tar': 'tar',
    'application/x-tcl': 'tcl',
    'application/x-tex': 'tex',
    'application/x-texinfo': 'texinfo',
    'image/tiff': 'tiff',
    'text/tab-separated-values': 'tsv',
    'application/x-font-ttf': 'ttf',
    'application/x-ustar': 'ustar',
    'application/voicexml+xml': 'vxml',
    'image/x-xbitmap': 'xbm',
    'application/xhtml+xml': 'xhtml',
    'application/vnd.ms-excel': 'xls',
    'application/xml': 'xsl',
    'image/x-xpixmap': 'xpm',
    'application/xslt+xml': 'xslt',
    'application/vnd.mozilla.xul+xml': 'xul',
    'image/x-xwindowdump': 'xwd',
    'application/vnd.visio': 'vsd',
    'audio/x-wav': 'wav',
    'image/vnd.wap.wbmp': 'wbmp',
    'text/vnd.wap.wml': 'wml',
    'application/vnd.wap.wmlc': 'wmlc',
    'text/vnd.wap.wmlsc': 'wmls',
    'application/vnd.wap.wmlscriptc': 'wmlscriptc',
    'video/x-ms-wmv': 'wmv',
    'application/font-woff': 'woff',
    'application/font-woff2': 'woff2',
    'model/vrml': 'wrl',
    'application/wspolicy+xml': 'wspolicy',
    'application/x-compress': 'z',
    'application/zip': 'zip'
};

export const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

export function addhttp(url: string) {
    if (!url) return '';
    // eslint-disable-next-line no-useless-escape
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) url = 'http://' + url;
    return url;
}

export function isValidEmail(email: string) {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}
